// Customize Bootstrap variables
@import "variables";

// Boostrap scss
@import "~bootswatch/dist/pulse/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/pulse/bootswatch";

// Utility classes
.pointer {
  cursor: pointer;
}

a.link-inherit {
  color: inherit;
}

a:hover.link-inherit {
  color: inherit;
}

a:visited.link-inherit {
  color: inherit;
}

.search-container {
  max-width: 300px;
}

h1.land {
  font-size: 17.5px;
  line-height: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  margin-bottom: 0;
}

.license-warning-circle {
  margin-left: 0.4rem;
  right: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: $warning;
}

h5 {
  font-size: 14px;
}

.tabs-orgs>a {
  padding: 0.5rem 0.7rem;
}

.tabs-orgs>.nav-link {
  color: #000;
}

.tabs-orgs>.nav-link.active {
  color: $primary;
  font-weight: bold;
}

.table-layout-fixed {
  table-layout: fixed;
}

.dropdown-toggle::after {
  vertical-align: 0.1em;
}

@for $i from 1 through 20 {
  .ddx-w-#{$i * 5} {
    width: $i * 5%;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}